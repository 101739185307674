import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, UpdateActivatedEvent, UpdateAvailableEvent } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DataService } from './data.service';
import { Events } from './events.service';
import { GlobalService } from './global.service';
import { MainService } from './main.service';

@Injectable({ providedIn: 'root' })
export class SwService {

  goRoot = false;

  constructor(
    public updates: SwUpdate,
    public appRef: ApplicationRef,
    public g: GlobalService,
    public events: Events,
    public main: MainService
  ) {
    updates.available.subscribe(async (event: UpdateAvailableEvent) => {
      const appData = event.available.appData as IAppData;
      const currentVersion = this.main.appInfo.version;
      const clicked = await Swal.fire({
        title: 'Atualizar agora?',
        html: 'Uma nova versão do sistema está disponível.' + (!!appData && !!appData.version && appData.version !== currentVersion ? '<br>Atual: <span style="color: red">' + currentVersion + '</span> - Nova: <span style="color: green;">' + appData.version + '</span>' : ''),
        icon: 'question',
        toast: false,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Mais tarde',
        confirmButtonText: 'Atualizar',
        heightAuto: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });
      if (clicked.isConfirmed === true) {
        updates.activateUpdate().then(() => {
          if (this.goRoot) {
            document.location.href = '/';
          } else {
            document.location.reload();
          }
        });
      }
      if (clicked.isDenied === true) { }
    });
    updates.activated.subscribe((event: UpdateActivatedEvent) => {
      this.g.alert('Nova versão instalada.', 'Sucesso!', 'success', 'center');
    });

    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());

    this.events.subscribe('sw:check', () => {
      this.check().then(() => {
        this.updates.checkForUpdate();
      }, () => {
        this.g.alert('Nenhuma versão nova', 'Tudo certo', 'success');
      }).catch(() => {
        this.g.alert('Não foi possível verificar atualização', 'Ops!', 'error');
      });
    });
  }

  check(goRoot = false): Promise<any> {
    this.goRoot = goRoot;
    if (!this.updates.isEnabled) {
      return Promise.resolve(false);
    }
    return this.updates.checkForUpdate();
  }
}

export interface IAppData {
  version: string;
}
