import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { HttpClient } from '@angular/common/http';
import { Events } from './events.service';
import { Platform, NavController } from '@ionic/angular';
import { DataService } from './data.service';
import { v4 as uuidv4 } from 'uuid';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { environment } from '../../environments/environment';
import { SecureStorageService } from './secure-storage.service';
import { forEach } from 'lodash';
import { version } from 'src/version';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  public deviceInfo: IDeviceInfo;
  public appInfo: IAppInfo;
  public isBrowser: boolean;
  public isMobile: boolean;
  public debug = !environment.production;
  private bid = '_secure__ls__sign__enclave';

  constructor(
    private g: GlobalService,
    private http: HttpClient,
    private events: Events,
    private platform: Platform,
    private data: DataService,
    private ss: SecureStorageService,
    private navCtrl: NavController
  ) { }

  static factory(appLoadService: MainService) {
    return () => appLoadService.init();
  }

  private init(): Promise<void> {
    return new Promise((resolve) => {
      this.platform.ready().then(async () => {

        await this.checkIsBrowser();
        await Promise.all([
          this.fillDeviceInfo(),
          this.fillAppInfo(),
          this.registerEvents(),
          this.keyboardAdjust()
        ]);

        this.ss.configure(this.isBrowser, this.deviceInfo.uuid);
        this.ss.init().then(async ok => {
          await this.data.init();
          return resolve();
        }, nook => {
          this.navCtrl.navigateRoot('/error/' + nook).then(() => null);
        }).finally(() => resolve());

        if (this.isMobile) {
          // CodePush.notifyApplicationReady();
        }
      });

    });
  }

  private async fillDeviceInfo() {
    const device = await Device.getInfo();
    this.deviceInfo = {
      model: device.model,
      platform: device.platform,
      uuid: device.uuid,
      version: device.osVersion,
      manufacturer: device.manufacturer
    };
    if (this.isBrowser) {
      let bid = localStorage.getItem(this.bid);
      if (!bid) {
        bid = uuidv4().toUpperCase();
        localStorage.setItem(this.bid, this.g.c(bid));
      } else {
        bid = this.g.d(bid);
      }
      this.deviceInfo.uuid = bid;
    }
    Promise.resolve();
  }

  private async fillAppInfo() {
    if (this.isBrowser) {
      this.appInfo = {
        name: 'ProjeTIM',
        package: 'com.ludufre.projetim',
        version
      };
    } else {
      const info = await App.getInfo();
      this.appInfo = {
        name: info.name,
        package: info.id,
        version,
        build: info.build,
        binaryVersion: info.version
      };
    }
    Promise.resolve();
  }

  private checkIsBrowser(): Promise<boolean> {
    this.isBrowser = true;
    this.isMobile = false;
    return Promise.resolve(true);
    // try {
    //   this.http2.setServerTrustMode('default')
    //     .then(
    //       () => this.isBrowser = false,
    //       () => this.isBrowser = true
    //     ).finally(() => {
    //       this.isMobile = !this.isBrowser;
    //     });
    //   Promise.resolve();
    // } catch (err) {
    //   return Promise.reject();
    // }
  }

  private registerEvents() {
    this.events.subscribe('user:logout', (friendly: boolean = false) => {
      // Fecha todos os alertas
      this.g.hideAlerts();

      forEach(this.g.resume, (obj, name) => {
        delete this.g.resume[name];
      });
      forEach(this.g.pause, (obj, name) => {
        delete this.g.pause[name];
      });
    });

    this.platform.resume.subscribe(() => {
      forEach(this.g.resume, (event, name) => {
        event();
      });
    });
    this.platform.pause.subscribe(() => {
      forEach(this.g.pause, (event, name) => {
        event();
      });
    });
  }

  private async keyboardAdjust(): Promise<any> {
    if (this.isBrowser) { return Promise.resolve(); }
    if (this.deviceInfo.platform === 'iOS') {
      Keyboard.setScroll({ isDisabled: false });
      Keyboard.setAccessoryBarVisible({ isVisible: true });
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }
    return Promise.resolve();
  }

}
export interface IDeviceInfo {
  manufacturer: string;
  model: string;
  platform: string;
  uuid: string;
  version: string;
}

export interface IAppInfo {
  name: string;
  package: string;
  build?: string | number;
  binaryVersion?: string;
  version: string;
}
