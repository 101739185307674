import { Component, NgZone, OnInit } from '@angular/core';
import { Platform, ActionSheetController, ModalController, NavController, MenuController, PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ApiService } from './engine/api.service';
import { AuthService } from './engine/auth.service';
import { GlobalService } from './engine/global.service';
import { Events } from './engine/events.service';
import { MainService } from './engine/main.service';
import { DataService, IUserData } from './engine/data.service';
import { IconService } from './engine/icon.service';
import { SecureStorageService } from './engine/secure-storage.service';
import { SwService } from './engine/sw.service';
import { LayoutService } from './engine/layout.service';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  user: IUserData;

  constructor(
    public data: DataService,
    public platform: Platform,
    public api: ApiService,
    public auth: AuthService,
    public events: Events,
    public g: GlobalService,
    public actionSheetCtrl: ActionSheetController,
    public modalCtrl: ModalController,
    public router: Router,
    public navCtrl: NavController,
    public main: MainService,
    public zone: NgZone,
    public menuCtrl: MenuController,
    public popCtrl: PopoverController,
    public icon: IconService,
    public ss: SecureStorageService,
    public sw: SwService,
    public layout: LayoutService,
  ) { }

  ngOnInit() {
    if (this.main.isMobile) {
      // this.updateService.ok();
    }
    if (this.main.isBrowser) {
      console.log(
        '%cEstamos contratando desenvolvedor Ionic/Angular + NodeJS no Rio de Janeiro! Envie seu currículo para devtools@sonartim.com.br',
        'font-size: 20px; background-color: #0072c6; color:#fff; padding: 6px;'
      );
    }

    if (this.main.isMobile) {
      this.deeplinksDefine();
    }

    this.listenEvents();
    this.g.popCtrl = this.popCtrl;
  }

  listenEvents() {
    this.events.subscribe('user:logout', () => {
      this.g.dismissAll(this.modalCtrl);
    });
    if (this.main.isMobile) {
      this.platform.resume.subscribe(() => {
        // this.updateService.backgroundUpdate();
      });
    }
  }

  async deeplinksDefine() {
    if (this.main.isMobile) {
      App.addListener('appUrlOpen', data => {
        console.log(data);
        // if (appLaunchUrl.url.startsWith('/login')) {
        //   console.log(appLaunchUrl.url);
        //   // const path: string = match.$link.path;
        //   // const params = match.$link?.queryString;
        //   // console.warn(`${path}?${params}`);
        //   // this.zone.run(() => {
        //   //   location.href = `${path}?${params}`;
        //   // }, (err: any) => {
        //   //   console.log(err);
        //   //   this.g.alert('Não foi possível continuar, tente novamente.', 'Ops!', 'error');
        //   // });
        // }
      });
    }
  }

  debug() {
    this.main.debug = !this.main.debug;
  }

}
