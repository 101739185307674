import { Injectable } from '@angular/core';
import {
  faChevronCircleRight,
  faCogs,
  faDebug,
  faSync,
} from '@fortawesome/pro-duotone-svg-icons';
import { FaIconLibrary, FaConfig } from '@fortawesome/angular-fontawesome';

@Injectable({ providedIn: 'root' })
export class IconService {

  constructor(
    private fa: FaIconLibrary,
    private fac: FaConfig
  ) {
    this.fac.defaultPrefix = 'fad';
    this.fac.fixedWidth = true;
    this.fac.fallbackIcon = faDebug;
    this.fa.addIcons(
      faDebug,
      faChevronCircleRight,
      faSync,
      faCogs
    );
  }
}
