import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { MainService } from './main.service';
import { DataService } from './data.service';
import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import * as moment from 'moment';
import { intersection } from 'lodash';

@Injectable()
export class RouteGuard implements CanActivate {

  private isLogged: boolean;
  private perms: string[];

  constructor(
    private main: MainService,
    private data: DataService,
    private navCtrl: NavController,
    private platform: Platform
  ) {
    this.data.isLogged.subscribe((is) => this.isLogged = is);
    this.data.permissions.subscribe((perms) => this.perms = perms);
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean {

    // const path: string = route.routeConfig.path;

    let permissions: string[] = [];
    if (!!route.data.permission) {
      if (typeof route.data.permission === 'string') {
        permissions.push(route.data.permission);
      } else {
        permissions = route.data.permission;
      }
    }

    if (permissions.length > 0 && intersection(this.perms, permissions).length === 0) {
      this.navCtrl.navigateRoot('/login', { animated: true, animationDirection: 'back' });
      this.hide();
      return false;
    }

    this.hide();
    return true;
  }

  hide() {
    if (this.main.isMobile) {
      this.platform.ready().then(() => {
        SplashScreen.hide();
      });
    }
  }

}
