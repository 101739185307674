import { NgModule, APP_INITIALIZER, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
// import { CodePush } from '@ionic-native/code-push/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
// import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { Http } from '@ionic-native/http/ngx';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { MainService } from './engine/main.service';
// import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { RouteGuard } from './engine/route.guard';
// import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
// import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { LocalNotifications } from '@capacitor/local-notifications';
// import { SecureStorage } from '@ionic-native/secure-storage/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({
      mode: 'ios',
      backButtonText: 'Voltar',
      scrollAssist: false
    }),
    LoadingBarModule,
    ServiceWorkerModule.register('OneSignalSDKWorker.js', { enabled: environment.production }),
  ],
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  providers: [
    // CodePush,
    // OneSignal,
    // UniqueDeviceID,
    // HTTP,
    // Diagnostic,
    CurrencyPipe,
    RouteGuard,
    // DocumentViewer,
    // PhotoViewer,
    // SecureStorage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    {
      provide: APP_INITIALIZER,
      useFactory: MainService.factory,
      deps: [MainService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
