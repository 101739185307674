import { Injectable, NgZone } from '@angular/core';
import { GlobalService } from './global.service';
// import { HTTP } from '@ionic-native/http/ngx';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Events } from './events.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MainService } from './main.service';
import { DataService } from './data.service';
import { NavController } from '@ionic/angular';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiService {

  public candy: string;
  public apiUrl = environment.endpoint.api;
  private token: string;
  private payload: string;
  private bitwise: string;
  private port = 443;

  constructor(
    public http: HttpClient,
    public events: Events,
    public g: GlobalService,
    // public http2: HTTP,
    public loadBar: LoadingBarService,
    public ng: NgZone,
    public main: MainService,
    public data: DataService,
    public navCtrl: NavController
  ) {
    this.init();
  }

  init() {
    this.data.auth.subscribe((auth) => {
      this.token = auth ? auth.token : null;
    });
    const p = this.g.crl({ device: this.main.deviceInfo, app: this.main.appInfo });
    const m = Math.ceil(p.length / 2);
    this.payload = p.substr(0, m);
    this.bitwise = p.substr(m);
    this.candy = '';
  }

  public call(method: string, type: 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download' = 'get', dataOut: any = null, supressExpired: boolean = false, loadBarRef = 'main'): Promise<any> {
    const hds = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-PROJETIM-PAYLOAD': this.payload,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-PROJETIM-BITWISE': this.bitwise,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-PROJETIM-LOKVAL': this.candy
    };

    if (!dataOut || !(dataOut instanceof FormData)) {
      hds['Content-Type'] = 'application/json; charset=utf-8';
    }
    if (this.token !== null) {
      hds['Authorization'] = 'Bearer ' + this.token;
    }

    return new Promise((resolve, reject) => {
      this.ng.run(() => this.loadBar.useRef(loadBarRef).start());
      this.http.request(type.toUpperCase(), `${this.apiUrl}:${this.port}/${method}`, {
        headers: hds,
        responseType: 'json',
        body: dataOut,
        observe: 'response'
      }).toPromise().then((data: HttpResponse<any>) => {
        // console.log('api.service', 'call()',
        //   { method, type, data: dataOut },
        //   { status: data.status, body: data.body }
        // );
        if (!this.g.isJSON(data.body) && data.status === 200) {

          reject({ msg: 'Ocorreu uma falha inesperada. Incidente será reportado para equipe técnica. [C01]', code: 200 });
          return;
        }
        return resolve(data.body);
      }, (err: HttpErrorResponse) => {
        // console.log('api.service', 'call()',
        //   { method, type, data: dataOut },
        //   { status: err.status, body: err.error }
        // );
        if (err.status === 401 && !supressExpired) {
          this.events.publish('user:logout');
          return;
        }
        if (err.status === 403) {
          this.events.publish('permissions:refresh');
          return reject(!!err.error && this.g.isJSON(err.error) && !!err.error?.msg ? err.error : { msg: 'Você não tem permissão para acessar este recurso!' });
        }
        if (err.status === 426) {
          // console.error('Mionimumn:' + err.error.minimum);
          this.navCtrl.navigateRoot('/update/' + err.error.minimum);
          return;
        }
        if (this.g.isJSON(err.error)) {
          if (err.error.isTrusted) {
            return reject({ msg: 'Ocorreu uma falha inesperada. Incidente será reportado para equipe técnica. [C02]' });
          }
          if (err.error.msg) {
            return reject(err.error);
          } else {
            return reject({ msg: 'Ocorreu uma falha inesperada. Incidente será reportado para equipe técnica. [C03]' });
          }
        } else {
          return reject({ msg: 'Ocorreu uma falha inesperada. Incidente será reportado para equipe técnica. [C04]', code: err.status });
        }
      }).catch(() =>
        reject({ msg: 'Ocorreu uma falha inesperada. Incidente será reportado para equipe técnica. [C06]', code: -1 })
      ).finally(() => this.ng.run(() => this.loadBar.useRef(loadBarRef).complete()));
    });
  }

  public download(method: string, type: 'get' | 'post' = 'get', dataOut: any = null, supressExpired: boolean = false): Promise<any> {
    const hds = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-PROJETIM-PAYLOAD': this.payload,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-PROJETIM-BITWISE': this.bitwise,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-PROJETIM-LOKVAL': this.candy
    };

    if (!dataOut || !(dataOut instanceof FormData)) {
      hds['Content-Type'] = 'application/json; charset=utf-8';
    }
    if (this.token !== null) {
      hds['Authorization'] = 'Bearer ' + this.token;
    }

    return new Promise((resolve, reject) => {
      this.http.request(type.toUpperCase(), `${this.apiUrl}/${method}`, {
        headers: hds,
        responseType: 'blob',
        body: dataOut,
        observe: 'response'
      }).toPromise().then((data: HttpResponse<any>) => {
        // console.log('api.service', 'download()',
        //   { method, type, data: dataOut },
        //   { status: data.status, body: data.body.length }
        // );
        resolve(data.body);
      }, (err: HttpErrorResponse) => {
        // console.log('api.service', 'call()',
        //   { method, type, data: dataOut },
        //   { status: err.status, body: err.error }
        // );
        if (err.status === 401 && !supressExpired) {
          this.events.publish('user:logout');
          return;
        }
        if (err.status === 403) {
          this.events.publish('permissions:refresh');
          return reject(!!err.error && this.g.isJSON(err.error) && !!err.error?.msg ? err.error : { msg: 'Você não tem permissão para acessar este recurso!' });
        }
        return reject({ msg: 'Ocorreu uma falha inesperada. Incidente será reportado para equipe técnica. [C07]', code: err.status });
      }).catch(() =>
        reject({ msg: 'Ocorreu uma falha inesperada. Incidente será reportado para equipe técnica. [C06]', code: -1 })
      );
    });
  }

  beta() {
    if (this.port === 443) {
      this.g.ask('Ativar API Beta?', 'Confirmação', ['Confirmar', 'Desistir'], 'question').then(
        () => { this.port = 8443; this.g.alert('API Beta ativada!', 'Sucesso!', 'success'); },
        () => null
      );
    } else {
      this.g.ask('Desativar API Beta?', 'Confirmação', ['Confirmar', 'Desistir'], 'question').then(
        () => { this.port = 443; this.g.alert('API Beta desativada!', 'Sucesso!', 'success'); },
        () => null
      );
    }
  }
}
