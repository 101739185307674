import { Injectable } from '@angular/core';
import * as SecureLS from 'secure-ls';
import { MainService } from './main.service';
import { GlobalService } from './global.service';
import 'capacitor-secure-storage-plugin';
import { Plugins } from '@capacitor/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
const { SecureStoragePlugin } = Plugins;

@Injectable({ providedIn: 'root' })
export class SecureStorageService {

  private isBrowser: boolean = null;
  private uuid: string = null;
  private vault: SecureLS = null;

  constructor(
    private g: GlobalService,
  ) { }

  public configure(isBrowser: boolean, uuid: string) {
    this.isBrowser = isBrowser;
    this.uuid = uuid;
  }

  public init(): Promise<void> {
    if (this.uuid === null || this.isBrowser === null) {
      throw new Error('SECURE-STORAGE: Configuração não realizada! (init)');
    }

    return new Promise((ok, nook) => {
      if (this.isBrowser) {
        try {
          this.vault = new SecureLS({
            isCompression: true,
            encodingType: 'aes',
            encryptionSecret: this.g.sha512(this.uuid)
          });
          return ok();
        } catch (err) {
          return nook('vault');
        }
      }
    });
  }

  public async get(key: string) {
    if (this.uuid === null || this.isBrowser === null) {
      throw new Error('SECURE-STORAGE: Configuração não realizada! (get, ' + key + ')');
    }
    try {
      if (this.isBrowser) {
        return JSON.parse(this.vault.get(this.g.crl(key)));
      } else {
        const { value } = await SecureStoragePlugin.get({ key });
        return JSON.parse(value);
      }
    } catch (err) {
      return undefined;
    }
  }

  public async set(key: string, value: any) {
    if (this.uuid === null || this.isBrowser === null) {
      throw new Error('SECURE-STORAGE: Configuração não realizada! (set, ' + key + ')');
    }
    try {
      if (this.isBrowser) {
        this.vault.set(this.g.crl(key), JSON.stringify(value));
      } else {
        await SecureStoragePlugin.set({ key, value: JSON.stringify(value) });
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  public async keys(): Promise<string[]> {
    try {
      let keys: string[];
      if (this.isBrowser) {
        keys = (this.vault as SecureLS).getAllKeys();
        return Promise.resolve(keys.map(o => o = this.g.drl(o)).filter((o) => o !== '_secure__ls__sign__enclave'));
      } else {
        const { value } = await SecureStoragePlugin.keys();
        return value;
      }
    } catch (err) {
      return Promise.reject();
    }
  }

  public async remove(key: string) {
    if (this.uuid === null || this.isBrowser === null) {
      throw new Error('SECURE-STORAGE: Configuração não realizada! (remove, ' + key + ')');
    }
    try {
      if (this.isBrowser) {
        this.vault.remove(this.g.crl(key));
      } else {
        await SecureStoragePlugin.remove({ key });
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  public async clear() {
    if (this.uuid === null || this.isBrowser === null) {
      throw new Error('SECURE-STORAGE: Configuração não realizada! (clear)');
    }
    try {
      if (this.isBrowser) {
        const bid = localStorage.getItem('_secure__ls__sign__enclave');
        const oatuhState = localStorage.getItem(this.isBrowser ? this.g.crl('oauth_state') : 'oauth_state');
        this.vault.clear();
        if (bid) {
          localStorage.setItem('_secure__ls__sign__enclave', bid);
        }
        if (oatuhState) {
          localStorage.setItem(this.isBrowser ? this.g.crl('oauth_state') : 'oauth_state', oatuhState);
        }
      } else {
        await SecureStoragePlugin.clear();
      }
      return true;
    } catch (err) {
      return false;
    }
  }

}
