import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteGuard } from './engine/route.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  // Não autenticado
  { path: 'login', loadChildren: () => import('./engine/login/login.module').then(m => m.LoginPageModule), canActivate: [RouteGuard] },

  // Autenticado
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule), canActivate: [RouteGuard], data: { permission: '87cc5ddf-765e-68ad-f336-df310c4c18b5' } },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
