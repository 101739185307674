import { Injectable } from '@angular/core';
import { AlertController, Platform, PopoverController } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { AlertButton, AlertInput } from '@ionic/core';
import { compress, decompress, compressToBase64, decompressFromBase64 } from 'lz-string';
import { FormGroup } from '@angular/forms';
import { Filesystem } from '@capacitor/filesystem';
import { App } from '@capacitor/app';
// import { DocumentViewer, DocumentViewerOptions } from '@ionic-native/document-viewer/ngx';
// import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { Device } from '@capacitor/device';
// import { SuperLoadingComponent } from '../components/super-loading/super-loading.component';
import { OverlayBaseController } from '@ionic/angular/util/overlay';
import Swal, { SweetAlertPosition } from 'sweetalert2';
import { forEach, includes, pull } from 'lodash';

@Injectable({ providedIn: 'root' })
export class GlobalService {

  public actionSheet: any;
  public uuid: string;
  public version: string = null;
  public regexNameSpace = /^[a-zA-ZáéíóúàèìòùãõâêîôûçÁÉÚÍÓÀÈÌÒÙÃÕÂÊÎÔÛÇ\ ]{1,64}$/;
  public resume: { [key: string]: any } = {};
  public pause: { [key: string]: any } = {};
  public popCtrl: PopoverController;
  public rekey = '6LfqXOEUAAAAADepE1RKzBm2nUvCw5Jbf0uEr80O';
  private alerts: HTMLIonAlertElement[] = [];
  private toasts: Array<any> = [];
  private tempPath: string;
  private loader: HTMLIonPopoverElement;

  constructor(
    private alertCtrl: AlertController,
    // private doc: DocumentViewer,
    // private photoViewer: PhotoViewer,
    private platform: Platform
  ) {
    this.platform.ready().then(async () => {
      // const info = await App.getInfo();
      // if (platform. === 'Android') {
      //   this.tempPath = this.file.applicationStorageDirectory + 'files/';
      // } else {
      //   this.tempPath = this.file.tempDirectory;
      // }
    });
  }

  // async showLoader(msg: string = 'Carregando...', onDismiss: any = null): Promise<any> {
  //   if (this.loader) {
  //     await this.loader.dismiss();
  //   }
  //   this.loader = await this.popCtrl.create({
  //     component: SuperLoadingComponent,
  //     componentProps: { text: msg },
  //     backdropDismiss: false,
  //     showBackdrop: true,
  //     cssClass: 'loading2'
  //   });

  //   if (onDismiss !== null && typeof onDismiss === 'function') {
  //     this.loader.onDidDismiss().then(() => {
  //       onDismiss();
  //     });
  //   }

  //   return await this.loader.present();
  // }
  // hideLoader() {
  //   if (this.loader) {
  //     this.loader.dismiss();
  //     this.loader = null;
  //   }
  // }

  async showAlert(msg: string, title: string, btns: AlertButton[] = null, inputs: AlertInput[] = null, dismiss: any = null) {
    const opts = {
      header: title,
      message: msg
    };

    if (!btns) {
      btns = [{
        text: 'Ok'
      }];
    }
    opts['buttons'] = btns;

    if (inputs) {
      opts['inputs'] = inputs;
    }

    this.alerts.push(
      await this.alertCtrl.create(opts)
    );

    this.alerts[this.alerts.length - 1].onWillDismiss().then(() => {
      this.alerts.splice(this.alerts.length - 1, 1);
      if (dismiss) { dismiss(); }
    });

    return await this.alerts[this.alerts.length - 1].present();
  }
  async hideAlerts() {
    let n = 0;
    return await Promise.all([
      Promise.all(this.alerts.map(async (alert) => {
        n++;
        await alert.dismiss();
      }))
    ]);
  }

  async showToast(msg: string, time: number = 3000) {
    this.toast(msg);
  }
  hideToast() {
    const toRemove = this.toasts;
    forEach(toRemove, (a, b) => {
      if (a) {
        this.toasts[b].dismiss();
        this.toasts[b] = null;
        pull(this.toasts, b);
      }
    });
  }

  getData(name: string): Promise<any> {
    return Promise.resolve(localStorage.getItem(name));
  }
  getAllData(): Promise<any> {
    return new Promise(async (ok, nook) => {
      const out = {};
      await Object.keys(localStorage).forEach((key: string) => {
        out[key] = localStorage.getItem(key);
      });
      return ok(out);
    });
  }
  setData(name: string, value: any = null): Promise<any> {
    if (value === null) {
      return Promise.resolve(localStorage.removeItem(name));
    }
    return Promise.resolve(localStorage.setItem(name, value));
  }
  clearData(): Promise<any> {
    Object.keys(localStorage).forEach((key: string) => {
      if (key !== '_secure__ls__sign__secure-storage') {
        localStorage.removeItem(key);
      }
    });
    return Promise.resolve();
  }

  nu2tel(tel: string): string {
    if (tel.length === 11) {
      return '(' + tel.substr(0, 2) + ') ' + tel.substr(2, 5) + '-' + tel.substr(7, 4);
    } else if (tel.length === 10) {
      return '(' + tel.substr(0, 2) + ') ' + tel.substr(2, 4) + '-' + tel.substr(6, 4);
    } else {
      return tel;
    }
  }

  pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) { s = '0' + s; }
    return s;
  }

  capitalize(inp: string): string {
    if (inp === null || inp === undefined || typeof inp !== 'string') { return null; }
    const words: string[] = inp.split(/ /g);
    let out = '';
    words.forEach((v, i) => {
      v = v.toLowerCase();
      v = v.substr(0, 1).toUpperCase() + v.substr(1);
      if (v === 'Da' || v === 'De' || v === 'Di' || v === 'Do' || v === 'Du') { v = v.toLowerCase(); }
      out += v + ' ';
    });
    return out.trim();
  }

  zipcode(inp: number): string {
    const t = this.pad(inp, 8);

    return t.substring(0, 5) + '-' + t.substring(5, 3);
  }

  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) { return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  isJSON(str: any) {
    if (typeof str === 'object') {
      return true;
    }
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  c(inp: any) {
    return compress(JSON.stringify(inp));
  }
  d(inp: any) {
    return JSON.parse(decompress(inp));
  }
  crl(inp: any) {
    return compressToBase64(JSON.stringify(inp));
  }
  drl(inp: any) {
    return JSON.parse(decompressFromBase64(inp));
  }

  sha512(inp: string): string {
    return CryptoJS.SHA512(inp).toString(CryptoJS.enc.Hex);
  }

  firstLastName(inp: string) {
    const t: string[] = inp.trim().split(' ');
    return this.capitalize(t[0] + ' ' + t[t.length - 1]);
  }

  formCheck(form: FormGroup) {
    forEach(form.controls, (inner) => {
      if (inner instanceof FormGroup) {
        this.formCheck(inner);
      } else {
        inner.markAsTouched();
        inner.updateValueAndValidity();
      }
    });
  }
  formPristine(form: FormGroup) {
    // form.get('input').setValidators(null);
    // form.updateValueAndValidity();

    form.setErrors(null);
    forEach(form.controls, (inner) => {
      if (inner instanceof FormGroup) {
        this.formPristine(inner);
      } else {
        inner.setErrors(null);
      }
    });

    form.markAsUntouched();
    form.markAsPristine();
    forEach(form.controls, (inner) => {
      if (inner instanceof FormGroup) {
        this.formPristine(inner);
      } else {
        inner.markAsUntouched();
        inner.markAsPristine();
      }
    });

    // form.get('input').setValidators(valFn);
    forEach(form.controls, (inner) => {
      if (inner instanceof FormGroup) {
        this.formPristine(inner);
      } else {
        inner.updateValueAndValidity();
      }
    });
    form.updateValueAndValidity();
  }

  async asyncForEach(array: any, callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  file2Blob(path: string, type = 'image/jpeg'): Promise<Blob> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (window as any).resolveLocalFileSystemURL(path, (fileEntry: any) => {
        fileEntry.file((resFile: any) => {
          const reader = new FileReader();
          reader.onloadend = (evt: any) => {
            const imgBlob: Blob = new Blob([evt.target.result], { type: 'image/jpeg' });
            resolve(imgBlob);
          };
          reader.onerror = (e) => {
            reject(e);
          };
          reader.readAsArrayBuffer(resFile);
        });
      }, (err: any) => {
        reject(err);
      });
    });
  }

  openBase64(base64: string, name: string, type: string, title: string = null): Promise<void> {
    return Promise.reject();
    // return new Promise(async (ok, nook) => {
    //   const blob: Blob = await this.base64ToBlob(base64, type);

    //   await Filesystem.deleteFile({ path: this.tempPath });

    //   await Filesystem.writeFile({
    //     path: this.tempPath + name,
    //     encoding: Encoding.
    //     data: blob
    //   });

    //   exists = await this.file.checkFile(this.tempPath, name);

    //   if (!exists) {
    //     return nook('Não foi possível abrir o arquivo.');
    //   }

    //   if (type === 'application/pdf') {
    //     const opts: DocumentViewerOptions = {
    //       title: title || name
    //     };
    //     this.doc.viewDocument(this.tempPath + name, type, opts, null, () => {
    //       this.file.removeFile(this.tempPath, name).then(() => null, () => null);
    //       return ok();
    //     }, () => nook('O App responsável em abrir o PDF não está instalado.')
    //       , () => nook('Não foi possível abrir o PDF.')
    //     );
    //   } else {
    //     this.photoViewer.show(this.tempPath + name);
    //     return ok();
    //   }
    // });
  }

  randomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  protect(inp: string, k: string) {
    const secure: CryptoJS.WordArray = CryptoJS.TripleDES.encrypt(inp, this.sha512(k));

    return secure.toString();
  }
  unprotect(inp: string, k: string) {
    return CryptoJS.TripleDES.decrypt(inp, this.sha512(k)).toString(CryptoJS.enc.Utf8);
  }

  b64download(blob: BlobPart, name: string, type: string = 'application/octet-stream') {
    // Avaliar utilização do novo método base64ToBlob
    const newBlob = new Blob([blob], { type });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    // link.download = name;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  toast(text: string, title: string = null, icon: 'warning' | 'error' | 'success' | 'info' | 'question' = 'info', position: SweetAlertPosition = 'bottom-end', timer = 1500) {
    Swal.fire({
      title: title || text,
      text: !!title ? text : null,
      timer,
      icon,
      toast: true,
      timerProgressBar: true,
      position,
      showConfirmButton: false
    });
  }

  // async showAlert(msg: string, title: string, btns: AlertButton[] = null, inputs: AlertInput[] = null, dismiss: any = null) {
  alert(text: string, title: string = null, icon: 'warning' | 'error' | 'success' | 'info' | 'question' = 'info', position: SweetAlertPosition = 'center', timer = 4000): Promise<any> {
    return Swal.fire({
      title: title || text,
      text: !!title ? text : null,
      timer,
      icon,
      toast: false,
      timerProgressBar: true,
      position,
      showConfirmButton: true,
      heightAuto: false
    });
  }

  ask(text: string, title: string = null, labels: [string, string], icon: 'warning' | 'error' | 'success' | 'info' | 'question' = 'question', position: SweetAlertPosition = 'center'): Promise<void> {
    return new Promise(async (ok, nook) => {
      const clicked = await Swal.fire({
        title: title || text,
        text: !!title ? text : null,
        icon,
        toast: false,
        timerProgressBar: true,
        position,
        heightAuto: false,

        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: labels[1],
        confirmButtonText: labels[0],
      });
      if (clicked.isConfirmed === true) {
        return ok();
      } else {
        return nook();
      }
    });
  }

  // Converte complemento do SGF para array
  complements2Array(o: string) {
    o = o.toUpperCase();
    o = o.replace(' BL0', '');
    o = o.replace(' CJ0', '');
    o = o.replace(',LT ', ' LT');
    o = o.replace('QD ', 'QD');

    const f = [];
    o.split(' ').forEach((v, k) => {
      if (includes(['QD', 'CJ', 'BL', 'LT'], v.substr(0, 2))) {
        f.push({ code: v.substr(0, 2), value: v.substr(2) });
        if (v.substr(0, 2) === 'QD') {
          const sector = v.substr(2).match(/^[A-Z\-\/]{1,8}(.+)$/);
          if (v !== null) {
            f[f.length - 1]['sector'] = f[f.length - 1]['value'].replace(sector[1], '');
            f[f.length - 1]['value'] = sector[1];
          }
        }
      }
    });
    const via = o.match(/\ V(.+?)$/);
    if (via !== null) {
      f.push({ code: 'V', value: via[1] });
    }
    return f;
  }

  getValue(collection: any[], key: any, keyName = 'id', valueName = 'label') {
    const fnd = collection.find((o) => o[keyName] === key);
    if (fnd === null || fnd === undefined) {
      return null;
    }
    return fnd[valueName] || null;
  }

  validateCpf(value: string) {
    value = value.replace(/[^0-9]/g, '');
    if (value == null || value.length !== 11 || value.match(/^([0-9])\1*$/g)) {
      return false;
    }
    let mult = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let sum = 0;
    mult.forEach((m, i) => {
      const n = +value.substr(i, 1);
      sum += n * m;
    });
    let d = sum % 11;
    if (d >= 10) { d = 0; }
    if (+value.substr(9, 1) !== d) {
      return false;
    }
    sum = 0;
    mult = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    mult.forEach((m, i) => {
      const n = +value.substr(i, 1);
      sum += n * m;
    });
    d = sum % 11;
    if (d >= 10) { d = 0; }
    if (+value.substr(10, 1) !== d) {
      return false;
    }
    return true;
  }

  validateCnpj(value: string) {
    value = value.replace(/[^0-9]/g, '');
    if (value == null || value.length !== 14 || value.match(/^([0-9])\1*$/g)) {
      return false;
    }
    let mult = [6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9];
    let sum = 0;
    mult.forEach((m, i) => {
      const n = +value.substr(i, 1);
      sum += n * m;
    });
    let d = sum % 11;
    if (d >= 10) { d = 0; }
    if (+value.substr(12, 1) !== d) {
      return false;
    }
    sum = 0;
    mult = [5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9];
    mult.forEach((m, i) => {
      const n = +value.substr(i, 1);
      sum += n * m;
    });
    d = sum % 11;
    if (d >= 10) { d = 0; }
    if (+value.substr(13, 1) !== d) {
      return false;
    }
    return true;
  }

  async dismissAll(overlayCtrl: OverlayBaseController<any, any>) {
    let overlay = await overlayCtrl.getTop();
    while (overlay) {
      if (!(await overlay.dismiss())) {
        return;
      }
      overlay = await overlayCtrl.getTop();
    }
  }

  compareVersion(bigger: string | number, lower: string | number): boolean {
    let i: number;
    let cmp: number;
    const a = (bigger + '').split('.');
    const b = (lower + '').split('.');
    const len = Math.max(a.length, b.length);
    for (i = 0; i < len; i++) {
      if (a[i] === undefined) {
        a[i] = '0';
      }
      if (b[i] === undefined) {
        b[i] = '0';
      }
      cmp = parseInt(a[i], 10) - parseInt(b[i], 10);
      if (cmp !== 0) {
        return (cmp < 0 ? false : true);
      }
    }
    return null;
  }

  resizeBase64Img(dataURL: string, type: string, maxWidth: number, maxHeight: number): Promise<string> {
    return new Promise((ok, nook) => {
      if (dataURL.indexOf(',') === -1) {
        if (!!type) {
          dataURL = 'data:' + type + ';base64,' + dataURL;
        } else {
          return nook('Tipo não foi informado.');
        }
      }

      const img = new Image();
      img.onload = () => {
        let scale: number;
        if (img.width < maxWidth) {
          scale = maxWidth / img.width;
        } else {
          scale = maxHeight / img.height;
        }
        const newWidth = img.width * scale;
        const newHeight = img.height * scale;
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.height = newHeight;
        canvas.width = newWidth;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
        return ok(canvas.toDataURL());
      };
      img.src = dataURL;
    });

  }

  base64ToBlob(base64: string, type: string = null): Promise<any> {
    return new Promise(async (ok, nook) => {
      if (base64.indexOf(',') === -1 && !!!type) {
        return nook('Tipo não foi informado.');
      }

      if (base64.indexOf(',') > -1) {
        const ms: RegExpMatchArray = base64.match(/^data:(.+?);base64,/);
        base64 = base64.substr(base64.indexOf(','));
        type = ms[1];
      }

      const bytes: string = atob(base64);
      const byteNumbers = new Array(bytes.length);
      for (let i = 0; i < bytes.length; i++) {
        byteNumbers[i] = bytes.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob: Blob = new Blob([byteArray], { type });

      return ok(blob);
    });
  }

}
