import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SecureStorageService } from './secure-storage.service';

@Injectable({ providedIn: 'root' })
export class DataService {

  public auth = new BehaviorSubject<IAuthData>(null);
  public permissions = new BehaviorSubject<string[]>([]);
  public user = new BehaviorSubject<IUserData>(null);
  public isLogged = new BehaviorSubject<boolean>(null);

  constructor(
    private ss: SecureStorageService
  ) { }

  public init(): Promise<void> {
    return new Promise((ok) => {
      Promise.all([
        this.ss.get('auth'),
        this.ss.get('permissions'),
        this.ss.get('user')
      ]).then((ret) => {
        if (ret[0]) { this.auth.next(ret[0]); }
        if (ret[1]) { this.permissions.next(ret[1]); }
        if (ret[2]) { this.user.next(ret[2]); }
        this.isLogged.next(!!ret[0]);

        this.isLogged.subscribe((is) => {
          if (is === false) {
            this.clear();
          }
        });
      }).finally(() => ok());
    });
  }

  public save(): Promise<any> {
    return Promise.all([
      this.ss.set('auth', this.auth.value),
      this.ss.set('permissions', this.permissions.value),
      this.ss.set('user', this.user.value)
    ]);
  }

  public clear(): Promise<any> {
    // Saved
    this.auth.next(null);
    this.permissions.next([]);
    this.user.next(null);

    return this.ss.clear();
  }
}

export interface IAuthData {
  token: string;
  refresh: string;
}

export interface IUserData {
  name: string;
  email: string;
  photo: string;
}
