import { Injectable, NgZone } from '@angular/core';
import { Events } from './events.service';
import { MainService } from './main.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SecureStorageService } from './secure-storage.service';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LayoutService {

  public collapsed = false;
  public collapsedForced = false;
  public expanded = false;
  public darkMode = false;
  public header = new BehaviorSubject<ITitle>(null);

  constructor(
    public main: MainService,
    public events: Events,
    public zone: NgZone,
    public ss: SecureStorageService,
    public platform: Platform
  ) {
    this.init();
  }

  init() {
    const prefers: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
    // eslint-disable-next-line import/no-deprecated
    prefers.addListener((ev) => {
      this.toogleDarkmode(ev.matches);
    });
    this.ss.get('darkMode').then((is) => {
      this.toogleDarkmode(is === null || is === undefined ? prefers.matches : is);
    });


    if (this.main.isBrowser) {
      const collapse: MediaQueryList = window.matchMedia('(max-width: 1200px)');
      // eslint-disable-next-line import/no-deprecated
      collapse.addListener((ev) => {
        this.zone.run(() => this.collapsedForced = ev.matches);
      });
      this.collapsedForced = collapse.matches;
    } else {
      StatusBar.setOverlaysWebView({ overlay: false });
      StatusBar.setBackgroundColor({ color: '#004990' });
    }
  }

  toogleDarkmode(is: boolean = null) {
    return;
    this.darkMode = is === null ? !this.darkMode : is;
    document.body.classList.toggle('dark', this.darkMode);
    if (this.main.isMobile) {
      if (this.platform.is('android')) {
        StatusBar.setBackgroundColor({ color: '#ffffff' });
      } else {
        if (this.darkMode) {
          StatusBar.setStyle({ style: Style.Light });
        } else {
          StatusBar.setStyle({ style: Style.Default });
        }
      }
    }
    this.ss.set('darkMode', this.darkMode);
  }
}

export interface ITitle {
  title: string;
  subtitle?: string;
  icon: string;
  description: string;
}
