import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { GlobalService } from './global.service';
import { Events } from './events.service';
import { NavController } from '@ionic/angular';
import { DataService } from './data.service';
import { MainService } from './main.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    public events: Events,
    public api: ApiService,
    public g: GlobalService,
    public navCtrl: NavController,
    public data: DataService,
    public main: MainService,
  ) {
    this.registerEvents();
  }

  public process(data: { token: string; refresh: string }): Promise<void> {
    return new Promise(async (ok, nook) => {
      try {
        const { name, email, photo } = JSON.parse(atob(data.token.split('.')[1]));
        this.data.user.next({ name, email, photo });
        this.data.auth.next(data);
        this.data.isLogged.next(true);
        await this.data.save();
        return ok();
      } catch (err) {
        console.error(err);
        return nook({ mgs: 'Falha ao salvar sessão.' });
      }
    });
  }

  private registerEvents() {
    this.events.subscribe('user:logout', (friendly: boolean = false) => {
      this.logout().then(() => {
        this.g.toast(null, 'Saindo...', 'info');
        this.navCtrl.navigateRoot('/login', { animated: true, animationDirection: 'back' }).then(() => {
          if (!friendly) {
            this.g.alert(null, 'Sessão expirada!', 'info');
          }
        });
      });
    });
  }

  private logout(): Promise<void> {
    return new Promise((ok) => {
      this.api.call('token', 'delete', null, true).then(
        () => ok(),
        () => ok()
      ).finally(() => {
        this.data.isLogged.next(false);
      });
    });
  }

  private async refreshTheToken(): Promise<any> {

  }
}

