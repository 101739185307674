export const environment = {
  production: true,
  endpoint: {
    api: 'https://api-projetim.sonartim.com.br',
    ws: 'wss://ws-projetim.sonartim.com.br',
    oauth: 'https://link.sonartim.com.br'
  },
  push: {
    app: 'd13d0348-1965-4910-90a5-9cbf478eaaa1',
    safari: '',
    google: ''
  },
  oauth: {
    clientId: 'acdd12eee1879498c92a4e3f23fe4285d337'
  }
};
